import { Modal, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';

const UserBlockedModal = () => {
  const onCloseClick = () => window.history.back();
  return (
    <RenderInBody>
      <Modal
        title={t('javascript.error_page.error')}
        danger
        iconClosable
        onCloseClick={onCloseClick}
      >
        <p>{t('javascript.casino.errors.game_launch_blocked')}</p>
      </Modal>
    </RenderInBody>
  );
};

export default UserBlockedModal;
