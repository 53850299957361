import { useEffect } from 'react';
import PropTypes from 'prop-types';
import RedirectToUrl from '../redirect_to_url';
import MobileIframe from './mobile_iframe';

const BlueprintGame = (props) => {
  const { url, mobileIframe } = props;
  // Effect for listening to messages
  useEffect(() => {
    const handleResize = () => {
      const gameIframe = document.getElementById('gl-iframe');
      const data = {
        type: 'resize',
        width: gameIframe.clientWidth,
        height: gameIframe.clientHeight,
      };
      gameIframe.contentWindow.postMessage(data, '*');
    };

    const handleOrientation = () => {
      setTimeout(handleResize, 0);
    };

    const handleMessage = (e) => {
      const message = e.data;
      if (message.type === 'requestSize') {
        handleResize();
      }
    };

    window.addEventListener('message', handleMessage, false);
    window.addEventListener('resize', handleResize, false);
    window.addEventListener('orientationchange', handleOrientation, false);
    return () => {
      window.removeEventListener('message', handleMessage);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleOrientation);
    };
  }, []);

  if (!mobileIframe) return <RedirectToUrl url={url} />;

  return (
    <MobileIframe {...props} />
  );
};

BlueprintGame.propTypes = {
  url: PropTypes.string.isRequired,
  mobileIframe: PropTypes.bool.isRequired,
};

export default BlueprintGame;
