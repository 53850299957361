import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Overlay } from 'CasinoV2/lazy_components';
import useOverlay from 'CasinoV2/components/game_launch/launch_types/hooks/use_overlay';
import { loadNetent } from 'CasinoV2/components/game_launch/helpers';
import { Modal } from './shared';

const NetentGame = ({
  config, overlayUrl, provider, gameSessionId, gameVersionId,
  gameData: { productGroup: { tags } },
}) => {
  const gameHolderRef = useRef(null);
  const gameRef = useRef(null);

  const showOverlay = useOverlay(overlayUrl, gameHolderRef, gameRef);

  useEffect(() => loadNetent({
    ...config,
    targetElement: 'game-root',
    enforceRatio: false,
    width: '100%',
    height: '100%',
  }), []);

  return (
    <Modal gameSessionId={gameSessionId} gameVersionId={gameVersionId} tags={tags}>
      {showOverlay && (
        <Overlay
          url={overlayUrl}
          provider={provider}
          gameRef={gameRef}
        />
      )}
      <div className="gl-iframe" ref={gameHolderRef}>
        <div id="game-root" />
      </div>
    </Modal>
  );
};

NetentGame.propTypes = {
  config: PropTypes.shape({
    staticServer: PropTypes.string.isRequired,
  }).isRequired,
  overlayUrl: PropTypes.string,
  provider: PropTypes.string.isRequired,
  gameSessionId: PropTypes.number.isRequired,
  gameVersionId: PropTypes.number.isRequired,
  gameData: PropTypes.shape({
    productGroup: PropTypes.shape({
      tags: PropTypes.instanceOf(Array),
    }),
  }),
};

NetentGame.defaultProps = {
  overlayUrl: null,
  gameData: {
    productGroup: {
      tags: [],
    },
  },
};

export default NetentGame;
