import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Spinner } from 'bv-components';
import { loadNetent } from 'CasinoV2/components/game_launch/helpers';

const NetentRedirect = ({ config }) => {
  useEffect(() => loadNetent(config), []);

  return <Spinner />;
};

NetentRedirect.propTypes = {
  config: PropTypes.shape({
    staticServer: PropTypes.string.isRequired,
  }).isRequired,
};

export default NetentRedirect;
