import PropTypes from 'prop-types';

import DesktopDefault from './desktop_default';
import RealisticFlash from './realistic/realistic_flash';

const NewRealisticGame = ({ isHtml5, ...rest }) => (
  isHtml5
    ? <DesktopDefault {...rest} />
    : <RealisticFlash {...rest} />
);

NewRealisticGame.propTypes = {
  isHtml5: PropTypes.bool.isRequired,
};

export default NewRealisticGame;
