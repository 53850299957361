import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { loadNetent } from 'CasinoV2/components/game_launch/helpers';
import MobileIframe from '../mobile_iframe';

const NetentIframe = (props) => {
  const { config } = props;
  const options = {
    ...config,
    targetElement: 'game-root',
    launchType: 'iframe',
    enforceRatio: false,
    width: '100%',
    height: '100%',
  };
  useEffect(() => loadNetent(options), []);

  return (
    <MobileIframe {...props} />
  );
};

NetentIframe.propTypes = {
  config: PropTypes.shape({
    staticServer: PropTypes.string.isRequired,
  }).isRequired,
};

export default NetentIframe;
