import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';
import { requireScript } from 'bv';

import { Overlay } from 'CasinoV2/lazy_components';
import { FlashRequiredModal } from 'CasinoV2/components/game_launch/modals';
import { Modal } from '../shared';

const RealisticFlash = ({
  url, overlayUrl, provider, gameSessionId, gameId,
  gameData: { productGroup: { tags } }, gameVersionId,
}) => {
  const [checkingFlash, setCheckingFlash] = useState(true);
  const [flashRequired, setFlashRequired] = useState();

  useEffect(() => {
    requireScript(window.JSBundles.swfobject).then(() => {
      setCheckingFlash(false);
      setFlashRequired(window.swfobject.getFlashPlayerVersion().major === 0);
    });
  }, []);

  useEffect(() => {
    if (checkingFlash) return;
    const { JSBundles } = window;

    if (!flashRequired) {
      Promise.all([
        JSBundles.realisticAjaxLauncher,
        JSBundles.realityCheckFlashPlugin,
      ].map(requireScript)).then(() => {
        window.loadGame(url);
      });
    }
  }, [checkingFlash, flashRequired, url]);

  if (checkingFlash) return <Spinner />;
  if (flashRequired) return <FlashRequiredModal />;

  return (
    <Modal
      gameSessionId={gameSessionId}
      gameId={gameId}
      tags={tags}
      gameVersionId={gameVersionId}
    >
      {overlayUrl && (
        <Overlay
          url={overlayUrl}
          provider={provider}
        />
      )}
      <div className="gl-iframe">
        <div id="game" />
      </div>
    </Modal>
  );
};

RealisticFlash.propTypes = {
  url: PropTypes.string.isRequired,
  overlayUrl: PropTypes.string,
  provider: PropTypes.string.isRequired,
  gameSessionId: PropTypes.number.isRequired,
  gameVersionId: PropTypes.number.isRequired,
  gameId: PropTypes.string.isRequired,
  gameData: PropTypes.shape({
    productGroup: PropTypes.shape({
      tags: PropTypes.instanceOf(Array),
    }),
  }),
};

RealisticFlash.defaultProps = {
  overlayUrl: null,
  gameData: {
    productGroup: {
      tags: [],
    },
  },
};

export default RealisticFlash;
