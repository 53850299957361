import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { refresh as refreshBalance } from 'bv-services/account-balance';

import { getLaunchType } from './helpers';

const GameLaunch = (props) => {
  const { provider } = props;

  // Notify about the game launch
  useEffect(() => refreshBalance, []);

  const Component = getLaunchType(provider);

  return <Component {...props} />;
};

GameLaunch.propTypes = {
  provider: PropTypes.string.isRequired,
  gameId: PropTypes.string.isRequired,
};

export default GameLaunch;
