import PropTypes from 'prop-types';
import NetentIframe from './netent_game/netent_iframe';
import NetentRedirect from './netent_game/netent_redirect';

const NetentGame = (props) => {
  const { mobileIframe, config } = props;
  return mobileIframe ? <NetentIframe {...props} /> : <NetentRedirect config={config} />;
};

NetentGame.propTypes = {
  config: PropTypes.shape({
    staticServer: PropTypes.string.isRequired,
  }).isRequired,
  mobileIframe: PropTypes.bool.isRequired,
};

export default NetentGame;
