import { camelize, titleize } from 'underscore.string';
import { compose } from 'underscore';
import { useContext } from 'react';
import Context from 'casino-context';
import { isDesktop } from 'bv';

import * as MobileLaunchTypes from '../launch_types/mobile';
import * as DesktopLaunchTypes from '../launch_types/desktop';

const { MobileDefault, ...MobileByProvider } = MobileLaunchTypes;
const { DesktopDefault, ...DesktopByProvider } = DesktopLaunchTypes;

const GetLaunchType = (provider) => {
  const { ownOverlay } = useContext(Context);

  return (isDesktop() || ownOverlay)
    ? DesktopByProvider[`${provider}Game`] || DesktopDefault
    : MobileByProvider[`${provider}Game`] || MobileDefault;
};

export default compose(GetLaunchType, camelize, titleize);
