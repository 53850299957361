import { requireScript } from 'bv';

// When netent sends a gameReady via netEntExtend we want to send it to ourselves
// To simulate the game is sending it as message
const onGameReady = () => {
  const { location } = window;
  window.postMessage('gameReady', `${location.protocol}//${location.host}`);
};

const onSuccess = (netEntExtend) => {
  window.netEntExtend = netEntExtend;

  window.netEntExtend.addEventListener('gameReady', onGameReady);
};

export default (config) => {
  requireScript(
    `${config.staticServer}/gameinclusion/library/gameinclusion.js`,
  ).then(() => {
    window.netent.launch(config, onSuccess, console.log);
  });

  return () => {
    const { netEntExtend } = window;
    if (netEntExtend) {
      netEntExtend.removeEventListener('gameReady', onGameReady);
    }
  };
};

/* eslint no-console: 'off' */
