import PropTypes from 'prop-types';
import RedirectToUrl from '../redirect_to_url';
import MobileIframe from './mobile_iframe';

const MobileDefault = (props) => {
  const { url, mobileIframe } = props;

  if (!mobileIframe) return <RedirectToUrl url={url} />;

  return (
    <MobileIframe {...props} />
  );
};

MobileDefault.propTypes = {
  url: PropTypes.string.isRequired,
  mobileIframe: PropTypes.bool.isRequired,
};

export default MobileDefault;
