import { Modal, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';

const FlashRequiredModal = () => (
  <RenderInBody>
    <Modal
      onCloseClick={window.history.back}
      actions={[{
        href: 'http://www.adobe.com/go/getflash',
        label: t('get_flash'),
        primary: true,
      }]}
      info
    >
      <p>{t('requires_flash')}</p>
    </Modal>
  </RenderInBody>
);

export default FlashRequiredModal;
