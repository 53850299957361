import { useRef } from 'react';
import PropTypes from 'prop-types';

import { Overlay } from 'CasinoV2/lazy_components';
import { Modal } from './shared';

const Default = ({
  url,
  overlayUrl,
  provider,
  platformId,
  gameSessionId,
  gameVersionId,
  gameData: { productGroup: { tags } },
}) => {
  const gameRef = useRef(null);

  return (
    <Modal
      gameSessionId={gameSessionId}
      gameVersionId={gameVersionId}
      tags={tags}
    >
      {overlayUrl && (
        <Overlay
          url={overlayUrl}
          provider={provider}
          gameRef={gameRef}
          platformId={platformId}
        />
      )}
      <iframe
        className="gl-iframe"
        src={url}
        ref={gameRef}
        allowFullScreen
        allow="autoplay"
      />
    </Modal>
  );
};

Default.propTypes = {
  url: PropTypes.string.isRequired,
  overlayUrl: PropTypes.string,
  provider: PropTypes.string.isRequired,
  platformId: PropTypes.number,
  gameSessionId: PropTypes.number,
  gameVersionId: PropTypes.number.isRequired,
  gameData: PropTypes.shape({
    productGroup: PropTypes.shape({
      tags: PropTypes.instanceOf(Array),
    }),
  }),
};

Default.defaultProps = {
  overlayUrl: null,
  platformId: null,
  gameSessionId: null,
  gameData: {
    productGroup: {
      tags: [],
    },
  },
};

export default Default;
