import { v as bvVar } from 'bv';
import { Button, Icon } from 'bv-components';
import PropTypes from 'prop-types';
import PanicButton from 'PanicButton';
import { t } from 'bv-i18n';
import { useFeature } from 'bv-hooks';
import Timer from '../../../timer';
import ProfitAndLoss from '../../../profit_and_loss';
import CTCIcon from '../../../ctc_icon';
import { useUKUser } from '../hooks/use_users_by_country';

const Header = ({
  gameSessionId, gameVersionId, tags = [], closeAction,
}) => {
  const {
    panicButtonEnabled,
    timerEnabled,
    profitAndLossEnabled,
  } = bvVar('casinoV2');
  const isUKUser = useUKUser();
  const [showDepositButton] = useFeature('in_game_deposit');

  const showProfitAndLoss = isUKUser && tags.includes('show-in-game-session-pnl') && profitAndLossEnabled;
  const showTimer = isUKUser && timerEnabled;
  const showCTC = Object.values(bvVar('crackTheCode')).includes(true) && gameSessionId && gameVersionId;
  const headerEnabled = panicButtonEnabled || showTimer || showProfitAndLoss || showDepositButton;

  if (!headerEnabled) return null;

  return (
    <div className="gl-modal__header">
      {panicButtonEnabled && (
        <PanicButton shortDesc source="casino-modal" />
      )}

      <section className="gl-pl-timer-wrapper">
        {showTimer && <Timer />}
        {showProfitAndLoss && <ProfitAndLoss gameSessionId={gameSessionId} />}
      </section>

      {showCTC && (
        <CTCIcon gameSessionId={gameSessionId} gameVersionId={gameVersionId} />
      )}

      {showDepositButton && (
        <>
          <div className="gl-modal__header-spacer" />
          <Button
            label={t('deposit')}
            href="/quick_deposit"
            className="gl-modal__deposit-button"
            primary
          />
        </>
      )}

      <span className="close-icon" onClick={closeAction}>
        <Icon
          id="close"
          big
          className="gl-modal__close"
          type="gl-mobile-modal-close-icon"
        />
      </span>
    </div>
  );
};

Header.propTypes = {
  gameSessionId: PropTypes.number,
  gameVersionId: PropTypes.number.isRequired,
  tags: PropTypes.instanceOf(Array),
  closeAction: PropTypes.func.isRequired,
};

Header.defaultProps = {
  gameSessionId: null,
  tags: [],
};

export default Header;
/* eslint no-console: 'off' */
