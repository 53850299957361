import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Spinner } from 'bv-components';
import { useFeature } from 'bv-hooks';

import RedirectToUrl from '../redirect_to_url';
import MobileIframe from './mobile_iframe';

const EvolutionGame = (props) => {
  const [overlayEnabled, featureResolved] = useFeature('casino_overlay');
  const { url, mobileIframe } = props;

  if (!featureResolved) return <Spinner />;
  if (!overlayEnabled && !mobileIframe) return <RedirectToUrl url={url} />;

  return (
    <MobileIframe {...props} scrolling="no" />
  );
};

EvolutionGame.propTypes = {
  mobileIframe: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
};

export default withRouter(EvolutionGame);
