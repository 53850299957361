import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { requireScript } from 'bv';
import { mainContextBack } from 'bv-helpers/location';
import { Overlay } from 'CasinoV2/lazy_components';
import { Modal } from './shared';

const PlayNGo = ({
  url, overlayUrl, provider, gameSessionId,
  gameVersionId, gameData: { productGroup: { tags } },
}) => {
  useEffect(() => {
    // PlayNGo expects us to expose this function globally
    window.Logout = mainContextBack;
    window.reloadgame = () => {
      window.location.reload();
    };

    requireScript(url);

    return () => {
      delete window.Logout;
      delete window.reloadgame;
    };
  }, [url]);

  return (
    <Modal gameSessionId={gameSessionId} gameVersionId={gameVersionId} tags={tags}>
      {overlayUrl && (
        <Overlay
          url={overlayUrl}
          provider={provider}
        />
      )}
      <div className="gl-iframe">
        <div id="game_holder" />
      </div>
    </Modal>
  );
};

PlayNGo.propTypes = {
  url: PropTypes.string.isRequired,
  overlayUrl: PropTypes.string,
  provider: PropTypes.string.isRequired,
  gameSessionId: PropTypes.number.isRequired,
  gameVersionId: PropTypes.number.isRequired,
  gameData: PropTypes.shape({
    productGroup: PropTypes.shape({
      tags: PropTypes.instanceOf(Array),
    }),
  }),
};

PlayNGo.defaultProps = {
  overlayUrl: null,
  gameData: {
    productGroup: {
      tags: [],
    },
  },
};

export default PlayNGo;
