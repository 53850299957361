import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

import { Modal, RenderInBody } from 'bv-components';

const LaunchErrorModal = ({ errorCode }) => (
  <RenderInBody>
    <Modal
      title={t('javascript.game_launch_error_modal.title')}
      onCloseClick={() => { window.history.back(); }}
      danger
    >
      <p>{`${t('javascript.game_launch_error_modal.message')} ${errorCode || ''}`}</p>
    </Modal>
  </RenderInBody>
);

LaunchErrorModal.propTypes = {
  errorCode: PropTypes.string.isRequired,
};

export default LaunchErrorModal;
