/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import { Modal, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';
import withLazyLoad from 'bv-lazy-load';
import { replaceFragment } from 'bv-helpers/location';
import { timeText, dateText } from '../../../helpers/date_text';

const StakeLimitsBreachModal = withLazyLoad()('stakeLimitsBreachModal');
const LossLimitsBreachModal = withLazyLoad()('lossLimitsBreachModal');

const CustomErrorMessageModal = ({ errorMessage }) => {
  const onCloseClick = () => window.history.back();

  if (!errorMessage.translated) {
    if (errorMessage.errorCode === 'RG931') {
      const expDate = new Date(errorMessage.expirationDate);
      errorMessage.title = t(errorMessage.title);
      errorMessage.message = t(errorMessage.message, {
        date: dateText(expDate), time: timeText(expDate),
      });
    } else if (errorMessage.errorCode === 'STAKE_LIMITS_BREACHED') {
      return (
        <StakeLimitsBreachModal
          title={() => t('stake_limits.breach_modal.casino_game_launch.error.title')}
          subtitle={() => t('stake_limits.breach_modal.casino_game_launch.error.description')}
          onEditBtnClick={() => replaceFragment('/safer_gambling/portal/stake_limits')}
          onCloseBtnClick={onCloseClick}
          translationsNamespace="stake_limits"
        />
      );
    } else if (errorMessage.errorCode === 'LOSS_LIMITS_BREACHED') {
      return (
        <LossLimitsBreachModal
          title={() => t('loss_limits.breach_modal.casino_game_launch.error.title')}
          subtitle={() => t('loss_limits.breach_modal.casino_game_launch.error.description')}
          onEditBtnClick={() => replaceFragment('/safer_gambling/portal/loss_limits')}
          onCloseBtnClick={onCloseClick}
          translationsNamespace="loss_limits"
        />
      );
    } else {
      return null;
    }
  }

  return (
    <RenderInBody>
      <Modal
        title={errorMessage.title}
        danger
        onCloseClick={onCloseClick}
        actions={[{
          label: t('close'), danger: true, inverse: true, onClick: onCloseClick,
        }]}
      >
        <p>
          {errorMessage.message}
        </p>
      </Modal>
    </RenderInBody>
  );
};

export default CustomErrorMessageModal;

CustomErrorMessageModal.propTypes = {
  errorMessage: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    errorCode: PropTypes.string.isRequired,
    translated: PropTypes.bool.isRequired,
    expirationDate: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
};
