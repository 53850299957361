import { useState, useEffect } from 'react';

const hasAddedNodes = (mutation) => mutation.addedNodes.length;

export default (overlayUrl, gameHolderRef, gameRef) => {
  const [showOverlay, setShowOverlay] = useState(false);

  // If we have casino overlay get the gameRef when added by the third party
  // It does replaceChild on the game holder to add their iframe
  useEffect(() => {
    if (!overlayUrl) return;

    new MutationObserver((mutations) => {
      const mutation = mutations.find(hasAddedNodes);

      if (mutation) {
        // eslint-disable-next-line prefer-destructuring,no-param-reassign
        gameRef.current = mutation.addedNodes[0];
        setShowOverlay(true);
      }
    }).observe(gameHolderRef.current, { childList: true });
  }, [overlayUrl, gameHolderRef]);

  return showOverlay;
};
