import { Modal, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';

export default () => (
  <RenderInBody>
    <Modal onCloseClick={() => { window.history.back(); }}>
      <p>{t('javascript.casino.game_location_unavailable')}</p>
    </Modal>
  </RenderInBody>
);
