import { useEffect, useContext } from 'react';
import { mainContextBack, setLocation } from 'bv-helpers/location';
import Context from 'casino-context';

export default () => {
  const { isAppWebView } = useContext(Context);

  useEffect(() => {
    const handleMessage = (e) => {
      const message = e.data;
      if (message.sender !== 'bingo') return;
      switch (message.name) {
        case 'gameQuit':
        case 'notifyCloseContainer':
          if (isAppWebView) {
            window.top.location = 'https://casino/lobby';
          } else {
            mainContextBack();
          }
          break;
        case 'homeButton':
          if (!isAppWebView) {
            setLocation('/');
          }
          break;
        default:
      }
    };

    window.addEventListener('message', handleMessage, false);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
};
