import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { v as bvVar } from 'bv';
import { RenderInBody } from 'bv-components';
import { Overlay } from 'CasinoV2/lazy_components';
import classnames from 'classnames';
import { mainContextBack } from 'bv-helpers/location';

import PostGameSummary from 'CasinoV2/components/post_game_summary';
import MobileHeader from './mobile_header';

const closeGame = () => {
  if (document.fullscreenElement) document.exitFullscreen().catch((err) => console.error(err));
  mainContextBack();
};

const MobileIframe = ({
  url, overlayUrl, mobileIframeOverlay, provider, platformId, gameSessionId, gameVersionId,
  gameData: { productGroup: { tags } }, scrolling,
}) => {
  const gameRef = useRef(null);
  const { panicButtonEnabled } = bvVar('casinoV2');
  const [postGame, setPostGame] = useState(false);
  const closeAction = Object.values(bvVar('crackTheCode')).includes(true)
    ? () => setPostGame(true)
    : closeGame;

  useEffect(() => {
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen().catch((err) => {
        console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    }

    const handleMessage = (e) => {
      const message = e.data;
      if (message === 'casino:lobby') {
        window.location = '/games/callback/lobby';
      } else if (message === 'casino:history') {
        window.location = '/games/callback/history';
      } else if (message === 'casino:banking') {
        window.location = '/games/callback/banking';
      }
    };

    window.addEventListener('message', handleMessage, false);
    document.documentElement.classList.add('casino-game-iframe-launched', 'body-scroll-lock');

    return () => {
      window.removeEventListener('message', handleMessage);
      document.documentElement.classList.remove('casino-game-iframe-launched', 'body-scroll-lock');
    };
  }, []);

  return (
    <RenderInBody>
      <div className="gl-modal-wrapper">
        <div className={classnames('gl-modal',
          { 'gl-modal--has-panic-button': panicButtonEnabled })}
        >
          <MobileHeader
            gameSessionId={gameSessionId}
            gameVersionId={gameVersionId}
            tags={tags}
            closeAction={closeAction}
          />
          <div className="gl-modal__body">
            {mobileIframeOverlay && overlayUrl && (
              <Overlay
                url={overlayUrl}
                provider={provider}
                gameRef={gameRef}
                platformId={platformId}
              />
            )}
            {url
              ? (
                <iframe
                  id="gl-iframe"
                  className="gl-iframe"
                  src={url}
                  ref={gameRef}
                  allow="autoplay"
                  {...scrolling ? { scrolling } : {}}
                />
              )
              : (
                <div className="gl-iframe">
                  <div id="game-root" />
                </div>
              )}
          </div>
        </div>
        {postGame && (
          <PostGameSummary
            closeGame={closeGame}
            gameSessionId={gameSessionId}
            gameVersionId={gameVersionId}
          />
        )}
      </div>
    </RenderInBody>
  );
};

MobileIframe.propTypes = {
  url: PropTypes.string,
  overlayUrl: PropTypes.string,
  mobileIframeOverlay: PropTypes.bool,
  provider: PropTypes.string.isRequired,
  platformId: PropTypes.number,
  gameSessionId: PropTypes.number,
  gameVersionId: PropTypes.number.isRequired,
  gameData: PropTypes.shape({
    productGroup: PropTypes.shape({
      tags: PropTypes.instanceOf(Array),
    }),
  }),
  scrolling: PropTypes.oneOf(['auto', 'yes', 'no']),
};

MobileIframe.defaultProps = {
  url: null,
  overlayUrl: null,
  mobileIframeOverlay: false,
  platformId: null,
  gameSessionId: null,
  gameData: {
    productGroup: {
      tags: [],
    },
  },
  scrolling: null,
};

export default MobileIframe;
/* eslint no-console: 'off' */
