import DesktopDefault from './desktop_default';
import usePragmaticEventHandler from '../event_handlers/use_pragmatic_event_handler';

const PragmaticGame = (props) => {
  usePragmaticEventHandler();

  return (
    <DesktopDefault {...props} />
  );
};

export default PragmaticGame;
