import { locale } from 'bv-i18n';

const dateToString = (date, opts = {}) => date.toLocaleDateString(locale(), opts);
const timeToString = (date, opts = {}) => date.toLocaleTimeString(locale(), opts);

export const timeText = (dateUnixTime) => timeToString(new Date(dateUnixTime), {
  hour: 'numeric',
  minute: 'numeric',
});

export const dateText = (dateUnixTime) => dateToString(new Date(dateUnixTime), {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
});
