import { useContext } from 'react';
import PropTypes from 'prop-types';

import { useFetch } from 'bv-hooks';
import { Spinner } from 'bv-components';
import { withScrollToTop } from 'bv-hocs';
import Context from 'casino-context';

import { fetchLaunchParametersJson } from 'CasinoV2/api';
import { useSelector } from 'react-redux';
import { selectTracking } from 'CasinoGlobal/redux/tracking/selectors';
import { Robots } from 'bv-metas';
import { RegionBlockModal, UserBlocked, CustomErrorMessage } from './modals';
import GameLaunch from './game_launch';

// TODO: Category stuff does not work as expect, will be dealed in other ticket
const GameLaunchContainer = (props) => {
  const { match: { params: { gameId } } } = props;
  const {
    embedded, ownOverlay, parentSessionId, gameSessionId,
  } = useContext(Context);

  const {
    path: trackingPath,
    lobby: trackingLobby,
    filterGroups: trackingFilterGroups,
    filterTags: trackiFgfilterTags,
    label: trackingLabel,
    componentId: trackingComponentId,
    bannerId: trackingBannerId,
    searchQuery: trackingSearchQuery,
    promoId: trackingPromoId,
  } = useSelector(selectTracking);
  // Fetch game details
  const [launchDetails, loading] = useFetch(() => (
    fetchLaunchParametersJson(gameId, {
      embedded,
      own_overlay: ownOverlay,
      parent_session_id: parentSessionId,
      original_game_session_id: gameSessionId,
      path: trackingPath,
      lobby: trackingLobby,
      filter_groups: trackingFilterGroups,
      filter_tags: trackiFgfilterTags,
      label: trackingLabel,
      component_id: trackingComponentId,
      banner_id: trackingBannerId,
      search_query: trackingSearchQuery,
      promo_id: trackingPromoId,
    })
  ));

  if (loading || !launchDetails) return <Spinner />;
  if (launchDetails.region_block) return <RegionBlockModal />;
  if (launchDetails.userBlocked) return <UserBlocked {...props} />;
  if (launchDetails.customErrorMessage) return <CustomErrorMessage {...launchDetails} />;

  return (
    <>
      <Robots content="noindex, nofollow" />
      <GameLaunch gameId={gameId} {...launchDetails} />
    </>
  );
};

GameLaunchContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      gameId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default withScrollToTop()(GameLaunchContainer);
